export const mergeUrl = (
  env,
  endpoint = "/leads/"
) => {
  let url = `https://mareg.exante.eu/api/v1${endpoint}`
  if (process.env.NEXT_ENV == "stage" || process.env.NEXT_PUBLIC_ENV == "stage") {
    url = `https://mareg-stage.exante.eu/api/v1${endpoint}`
  }
  return url
}

export const getFormId = () => {
  return process.env.NEXT_PUBLIC_FORM_ID
}
